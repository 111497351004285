
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

interface addSetting {
  title: string;
  item: string;
  defaultPrice: string;
  tax: string;
  expiredInDays: string;
  customPrice: any;
}

interface onlyId {
  schoolId: string;
  tipe: string;
}

interface items {
  listSchool: any;
}

export default defineComponent({
  name: "Setting Finance",
  components: {
    ErrorMessage,
    Field,
    Loading,
    Form
  },

  setup() {

    // loading overlay
    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const store = useStore();

    let items = reactive<items>({
      listSchool: [],
    });

    const addSettingValidator = Yup.object().shape({
      // schoolId: Yup.string().required('Nama Sekolah Wajib Diisi'),
      // title: Yup.string().required('Nama Setting Wajib Diisi'),
      // amount: Yup.string().required('Default Price Wajib Diisi'),
      // discountName: Yup.string().required('Nama Diskon Wajib Diisi'),
      // expiredDate: Yup.string().required('Exp Date Wajib Diisi'),
      // discount: Yup.string().required('Diskon Wajib Diisi'),
      // tax: Yup.string().required('Pajak wajib Diisi'),
    });

    const addSetting = reactive<addSetting>({
      title: "",
      item: "",
      defaultPrice: "",
      tax: "",
      expiredInDays: "",
      customPrice: [],
    });

    const onlyId = reactive<onlyId>({
      schoolId: "",
      tipe: "",
    });

    const saveChanges1 = () => {
      
      isLoading.value = true;

      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      setTimeout(() => {
        ApiService
        .postWithData('crmv2/main_invoice/invoice/office/invoice/setting?schoolId=' + onlyId.schoolId + '&itemType=' + onlyId.tipe, addSetting)
        .then((res) => {

          toast.success("Berhasil Tambah Setting Finance");

          router.push({ path: "/superadmin/setting-invoices" });

          isLoading.value = false;

        })
        .catch((e) => {
          
          toast.error(e.response.data.detail)

          isLoading.value = false;
        });

        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);

    };

    const getListSchool = () => {
      ApiService.getWithoutSlug('crmv2/main_school/school/school')
      .then((response) => {
        items.listSchool = response.data;
      });
    };

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_FINANCE);

      setCurrentPageBreadcrumbs("Finance", ["Data"]);

      getListSchool();
    });

    return {
      submitButton,
      saveChanges1,
      addSetting,
      addSettingValidator,
      onlyId,
      items,
      getListSchool,
      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
